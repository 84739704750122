.row{
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.middleLine{
    position: absolute;
    top: 30px;
    width: 100%;
}

.step{
    width: 60px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(196, 196, 196);
    height: 60px;
    width: 60px;
    /* border-radius: 50%; */
    color: #fff;
    font-size: 20px;
    transition: 0.3s;
}

.activeIcon {
    border: 1px solid var(--primary);
    transition: 0.3s;
    color: var(--primary);
    background-color: rgb(233, 233, 233);
}

.activedIcon{
    transition: 0.3s;
    background-color: var(--primary);
}

.step div:last-child{
    /* width: 50px; */
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
}

.content{
    margin-top: 10px;
}
