.form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.form input[type=number] {
    -moz-appearance: textfield;
}

.form{
    margin-top: 10px;
}

.form div:first-child{
    text-align: center;
    margin: 15px 0;
    font-size: 15px;
}

.inputs{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.form input {
    width: 16%;
    height: 50px;
    margin-left: 15px;
    text-align: center;
    font-size: 20px;
    border-radius: 5px;
    border: 1px solid rgb(143, 143, 143);
}

.form input:focus{
    background-color: rgba(224, 224, 224, 0.561);
    border: 2px solid #0191C8;
}

@media (max-width: 768px) {
    .form input{
        width:  40px;
        height: 40px;
    }
}
